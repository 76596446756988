<template>
    <div class="list-group-item" style="overflow: hidden;">
        <!-- style="display: -webkit-box !important;" -->
        <div class="row">
            <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                    <router-link
                        v-if="activityItem.Location && activityItem.Company"
                        :to="{
                            name: 'profile',
                            params: {
                                locationId: activityItem.Location.Id,
                                companyId: activityItem.Company.Id,
                            },
                        }"
                    >
                    <div
                        class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary"
                    >
                        <i class="fi flaticon-door"></i>
                    </div>
                    </router-link>
                    <div v-else>
                        <div
                            class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary"
                        >
                            <i class="fi flaticon-door"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col ml-n2">
                <p class="small text-gray-700 mb-0">
                    <transformed-link :link="getMessage"></transformed-link>
                </p>
                <small class="text-muted">{{ getDate }} {{ getTime }}</small>
            </div>
        </div>
        <!-- / .row -->
    </div>
</template>
<script>
import activityHelpersMixin from "./mixins/activityHelpersMixin";

export default {
    mixins: [activityHelpersMixin],
    props: ["activityItem"],
    computed: {
        getMessage() {
            let obj = null;
            try {
                obj = (this.activityItem.Description == null) ? {} : JSON.parse(this.activityItem.Description);
            } catch (e) {
                // no error
            }

            if (obj?.Error === "Invalid Doorcode")
                return this.$t("activity.door_wrong_pin",{
                    locationName: this.locationLink(),
                    companyName: this.companyLink(),
                    doorName: this.doorLink(this.activityItem.Device),
                });
            else
                return this.$t("activity.door_unlock_fail",{
                        locationName: this.locationLink(),
                        companyName: this.companyLink(),
                        doorName: this.doorLink(this.activityItem.Device),
                    });
        }
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>
